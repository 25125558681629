import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import List from "./list";
import ExternalLinkButton from "../common/external-link-button";
import TextDescription from "./text-description";

import { useAppLink } from "../../utils/hooks";

import ruleBk from "../../images/css/rules-bk.svg";

const RightSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    align-items: center;
    width: auto;
    padding: 1.5em 1em;
    > div {
        text-align: left;
    }
    ul {
        list-style-position: inside;
    }
    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        align-items: flex-start;
        width: calc(50% - 60px);
        padding: 1.5em 30px;
        > div {
            text-align: left;
        }
        ul {
            list-style-type: none;
            li {
                position: relative;
                padding-left: 14px;
                + li {
                    margin-top: 5px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #057ce5;
                    left: 0;
                    margin: auto;
                    bottom: 0;
                    top: 0;
                }
            }
        }
    }
`;

const Rule = styled.p`
    grid-column: 2/3;
    font-family: "Caveat Brush";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: -0.035em;
    text-align: center;

    @media (min-width: ${({ theme }) => theme.responsiveSpecialWindow}) {
        font-size: 30px;
        text-align: left;
    }

    @media (min-width: ${({ theme }) => theme.tabletFirstWidth}) {
        font-size: 40px;
    }
`;

const Description = styled(TextDescription)`
    text-align: left;
    font-size: 18px;
    padding-left: 0;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        text-align: center;
        font-size: 16px;
    }
`;

const RuleNumber = styled.span`
    color: #f2924a;
`;

const ImageDiv = styled.div`
    flex-grow: 1;
    flex-basis: 0;

    background-image: url(${ruleBk});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;

    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    > img {
        width: 100%;
        margin-bottom: -3px;
    }

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        width: 50%;
        max-width: 50%;

        display: flex;
        align-items: flex-end;
        position: relative;
        padding-left: 35px;
        > img {
            &.id-mobile {
                display: none;
            }
            &.id-desktop {
                display: block;
            }
            margin-bottom: 0;
            width: 100%;
            position: relative;
            z-index: 1;
            height: 100%;
            object-fit: cover;
        }
    }
`;

const RuleItem = ({ data, rule, buttonLabel }) => {
    const {
        image: { url, alt },
        order,
        summary,
        keypoints,
    } = data;
    const editLink = useAppLink(process.env.GATSBY_EDIT_PATH, true, true);

    return (
        <>
            <ImageDiv>
                <img src={url} alt={alt} />
            </ImageDiv>
            <RightSectionWrapper>
                <Rule>
                    {rule}&nbsp;
                    <RuleNumber>#{order}</RuleNumber>
                </Rule>
                <Description>{summary}</Description>
                <List data={keypoints} />
                <ExternalLinkButton
                    eventName="click_campaign_button"
                    label={buttonLabel}
                    href={editLink}
                />
            </RightSectionWrapper>
        </>
    );
};

RuleItem.propTypes = {
    data: PropTypes.shape({
        image: PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
        }).isRequired,
        order: PropTypes.number.isRequired,
        summary: PropTypes.string.isRequired,
        keypoints: PropTypes.string.isRequired,
    }).isRequired,
    rule: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
};

export default RuleItem;
