import PropTypes from "prop-types";
import styled from "styled-components";

const SectionTitle = styled.h3`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.04em;
    color: ${({ color, theme }) => color || theme.mainColor};
    margin: 5px 0;

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        line-height: 20px;
    }
`;

SectionTitle.propTypes = {
    color: PropTypes.string,
};

SectionTitle.defaultProps = {
    color: null,
};

export default SectionTitle;
