import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: #ffffff;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    padding: 35px 10px;

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        margin: 0 5px;
        padding: 35px 10px;
    }
`;

const StyledImage = styled.img`
    width: 200px;
`;

const StyledTitle = styled.p`
    width: 100%;
    max-width: 200px;

`;

const StyledDescription = styled.div`
    padding-top: 10px;
    p {
        span {
            color: ${({ theme }) => theme.mainColor};
        }
    }

`;

const BenefitItem = ({ data }) => {
    const {
        description,
        image: { url, alt },
        title,
    } = data;
    return (
        <Wrapper>
            <StyledImage src={url} alt={alt} />
            <StyledTitle className="sub2">{title}</StyledTitle>
            {/* eslint-disable-next-line react/no-danger */}
            <StyledDescription className="t2" dangerouslySetInnerHTML={{ __html: description }} />
        </Wrapper>
    );
};

BenefitItem.propTypes = {
    data: PropTypes.shape({
        description: PropTypes.string.isRequired,
        image: PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
        }).isRequired,
        order: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export default BenefitItem;
