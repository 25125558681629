import PropTypes from "prop-types";
import styled from "styled-components";

const SectionHeader = styled.h2`
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 43px;
    letter-spacing: -0.01em;
    margin: 5px 0;
    color: ${({ color }) => color || "inherit"};
`;

SectionHeader.propTypes = {
    color: PropTypes.string,
};

SectionHeader.defaultProps = {
    color: null,
};

export default SectionHeader;
