import styled from "styled-components";

const TextDescription = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.blackColor};
    padding-top: 20px;
`;

export default TextDescription;
