import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";
import SlideShow from "./slide-show";
import CenterFrame from "../common/center-frame";
import { useAppLink } from "../../utils/hooks";

import headerBackgroundImage from "../../images/css/header_background.svg";

const Wrapper = styled.section`
    width: auto;
    display: block;
    text-align: center;
    padding-top: 100px;
    position: relative;
    background-image: url(${headerBackgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .banner-header-container {
        position: relative;
        margin: 0 auto;
        z-index: 999;
        text-align: center;
        padding-bottom: 500px;
    }

    .slide-show {
        height: 1000px;
        left: 0;
        right: 0;
        overflow: visible;
        bottom: -2px;
        position: absolute;
        .slideshow-image {
            background-size: 130%;
        }
    }

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        .banner-header-container {
            padding-bottom: 40vw;
        }
    }
`;

const Title = styled.p`
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: -0.02em;
    text-align: center;

    @media (min-width: ${({ theme }) => theme.tabletFirstWidth}) {
        font-size: 40px;
        line-height: 51px;
    }

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        font-size: 60px;
        line-height: 68px;
    }
`;

const Subtitle = styled.div`
    max-width: 80%;
    height: auto;
    margin: 0 auto;
    text-align: center;
    padding: 15px 10px;

    margin-bottom: 5px;

    @media (min-width: ${({ theme }) => theme.tabletFirstWidth}) {
        font-size: 18px;
        line-height: 28px;
        max-width: 585px;
        padding: 1.1%;
        margin-bottom: 15px;
    }
`;

const Button = styled.button`
    width: 241px;
    height: 49.62px;
    background: #ffffff;
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.25);
    border-radius: 37px;
    border-color: transparent;
    margin: 0 auto;
    cursor: pointer;
    padding: 2px;

    @media (min-width: ${({ theme }) => theme.tabletFirstWidth}) {
        width: 212.35px;
        height: 40px;
    }

    @media (min-width: ${({ theme }) => theme.mobileViewWidth}) {
        width: 212.35px;
        height: 40px;
    }

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        width: 283px;
        height: 55px;
    }
`;

const OverlayImage = styled(({ className, image }) => {
    if (!image) {
        return null;
    }
    return <img className={className} src={image.url} alt={image.alt} />;
})`
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 60px;

    @media (min-width: ${({ theme }) => theme.mobileThirdWidth}) {
        width: 80px;
    }

    @media (min-width: ${({ theme }) => theme.tabletFirstWidth}) {
        width: 100px;
    }

    @media (min-width: ${({ theme }) => theme.responsiveSpecialWindow}) {
        width: 150px;
    }
`;

const Banner = ({
    advertisingIsOver,
    letsAmplify,
    subtitle,
    buttonLabel,
    images,
    overlayImage,
}) => {
    const link = useAppLink(process.env.GATSBY_EDIT_WIZARD_PATH, true, true);
    return <Wrapper>
        <CenterFrame>
            <div className="banner-header-container">
                {/* eslint-disable-next-line react/no-danger */}
                <Title> {advertisingIsOver} </Title>
                <Title> {letsAmplify} </Title>
                <Subtitle className="sub1">{subtitle}</Subtitle>
                <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button className="cta" type="button">
                        {buttonLabel}
                    </Button>
                </a>
                <OverlayImage image={overlayImage} />
            </div>
            <SlideShow className="slide-show" images={(images || []).map((img) => img.url)} />
        </CenterFrame>
    </Wrapper>
};

Banner.propTypes = {
    advertisingIsOver: PropTypes.string.isRequired,
    letsAmplify: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
        })
    ).isRequired,
    overlayImage: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
};

Banner.defaultProps = {
    overlayImage: null,
};

export const BannerData = graphql`
    fragment BannerData on DatoCmsHomePage3 {
        advertisingIsOver
        letsAmplify
        bannerSubtitle
        bannerScenes {
            url
        }
        overlayImage {
            url
            alt
        }
    }
`;

export default Banner;
