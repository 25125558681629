import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";

import SectionTitle from "./section-title";
import SectionHeader from "./section-header";
import TextDescription from "./text-description";
import CenterFrame from "../common/center-frame";

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        display: flex;
        flex-flow: column-reverse;
    }
`;

const RightSectionWrapper = styled.div`
    display: block;
    text-align: left;
    align-self: center;
    margin: 2em 0;
    padding: 0 2em;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        justify-content: center;
        text-align: center;
        max-width: ${({ theme }) => theme.tabletFirstWidth};
    }
`;

const AdCardsImage = styled.img`
    align-self: center;
    width: 100%;
    overflow: hidden;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        max-width: 400px;
    }
`;

const Description = styled(TextDescription)`
    font-size: 18px;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        font-size: 16px;
    }
`;

const WhyAmplify = ({
    data: {
        content,
        header,
        image: { url, alt },
        title,
    },
}) => (
    <CenterFrame>
        <Wrapper>
            <AdCardsImage src={url} alt={alt} />
            <RightSectionWrapper>
                <SectionTitle>{title.toUpperCase()}</SectionTitle>
                <SectionHeader>{header}</SectionHeader>
                <Description>{content}</Description>
            </RightSectionWrapper>
        </Wrapper>
    </CenterFrame>
);

WhyAmplify.propTypes = {
    data: PropTypes.shape({
        content: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        image: PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export const WhyAmplifyData = graphql`
    fragment WhyAmplifyData on DatoCmsHomePage3 {
        whyAmplify {
            title
            header
            content
            image {
                url
                alt
            }
        }
    }
`;

export default WhyAmplify;
