import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GoogleTag from "../../utils/google-tag";

const StyledLink = styled.a`
    background: ${({ isWhiteBk, theme }) => (isWhiteBk ? "white" : theme.mainColor)};
    box-shadow: 2px 2px 20px rgba(15, 117, 191, 0.25);
    border-radius: 37px;
    width: 241px;
    height: 49.62px;
    border-color: transparent;
    padding: 2px;

    text-align: center;

    text-decoration: none;
    font-weight: bold;
    font-size: 17px;
    line-height: 18px;
    letter-spacing: 0.02em;

    color: ${({ isWhiteBk, theme }) => (isWhiteBk ? theme.mainColor : "white")};
    &:hover,
    &:link,
    &:active,
    &:visited {
        color: ${({ isWhiteBk, theme }) => (isWhiteBk ? theme.mainColor : "white")};
    }
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        width: 283px;
        height: 55px;
    }
`;

const ExternalLinkButton = ({ eventName, label, isWhiteBk, href }) => (
    <StyledLink
        className="button"
        onClick={() => GoogleTag.handle(eventName)}
        isWhiteBk={isWhiteBk}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
    >
        {label}
    </StyledLink>
);

ExternalLinkButton.propTypes = {
    eventName: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    isWhiteBk: PropTypes.bool,
    label: PropTypes.string.isRequired,
};

ExternalLinkButton.defaultProps = {
    isWhiteBk: false,
};

export default ExternalLinkButton;
