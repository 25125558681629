import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";

import CampaignItem from "./campaign-item";
import CenterFrame from "../common/center-frame";

import calendlyTopBgTab from "../../images/css/calendly-top-bg-tab.svg";
import calendlyBottomCurve from "../../images/css/calendly-bottom-curve.svg";
import calendlyTopBg from "../../images/css/calendly-top-bg.svg";

const Wrapper = styled.div`
    display: block;
    position: relative;
    z-index: 2;
    margin-bottom: 5vw;

    &:after,
    &:before {
        content: " ";
        position: absolute;
        width: 100%;
        z-index: 0;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &:before {
        background-image: url(${calendlyTopBgTab});
        background-position: right top;
        top: -5em;
        bottom: 0;
    }
    &:after {
        background-image: url(${calendlyBottomCurve});
        background-position: center bottom;
        bottom: -5vw;
        height: 5vw;
    }

    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        &:before {
            top: -3em;
            background-image: url(${calendlyTopBg});
        }
    }
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        grid-template-columns: 1fr;
        align-items: center;
    }
`;

const Campaigns = (props) => {
    const { buttonLabel, campaigns } = props;
    const toDisplayCampaign = campaigns.find(({ currentDisplay }) => currentDisplay);

    return (
        <Wrapper>
            <CenterFrame>
                <Content>
                    {toDisplayCampaign && (
                        <CampaignItem data={toDisplayCampaign} buttonLabel={buttonLabel} />
                    )}
                </Content>
            </CenterFrame>
        </Wrapper>
    );
};

Campaigns.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    campaigns: PropTypes.arrayOf(
        PropTypes.shape({
            campaignType: PropTypes.string.isRequired,
            currentDisplay: PropTypes.bool.isRequired,
            description: PropTypes.string.isRequired,
            image: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired,
            }).isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export const CampaignsData = graphql`
    fragment CampaignsData on DatoCmsHomePage3 {
        campaigns {
            campaignType
            description
            image {
                url
                alt
            }
            title
            currentDisplay
        }
    }
`;

export default Campaigns;
