import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledDiv = styled.div`
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.mainColor};
    padding-top: 20px;

    ul {
        list-style-type: disc;
        padding-bottom: 1em;
        padding-left: 0;
        margin-left: 1em;

        @media (min-width: ${({ theme }) => theme.tabletFirstWidth}) {
            list-style-position: inside;
            margin-left: 0;
        }
    }

    span {
        color: ${({ theme }) => theme.blackColor};
    }

    @media (min-width: ${({ theme }) => theme.mobileViewWidth}) {
        text-align: left;
        font-size: 16px;
    }

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        line-height: 24.5px;
    }
`;

// eslint-disable-next-line react/no-danger
const List = ({ data }) => <StyledDiv dangerouslySetInnerHTML={{ __html: data }} />;

List.propTypes = {
    data: PropTypes.string.isRequired,
};

export default List;
