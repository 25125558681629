import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";

import DefaultTemplate from "../templates/default-template";
import Banner from "../components/home/banner";
import WhyAmplify from "../components/home/why-amplify";
import HowItWorks from "../components/home/how-it-works";
import Rules from "../components/home/rules";
import Members from "../components/home/members";
import Benefits from "../components/home/benefits";
import Campaigns from "../components/home/campaigns";
import { useLocationInitialization } from "../utils/hooks";
import ContactUs from "../components/home/contact-us";

const Home = ({ data, location, pageContext: { locale } }) => {
    const dato = data.allDatoCmsHomePage3.nodes[0];
    const i18nData = data.allDatoCmsI18N.nodes[0];

    useLocationInitialization(location);

    return (
        <DefaultTemplate
            i18nData={i18nData}
            locale={locale}
            pathname={location.pathname}
            title={i18nData.home}
            seoMetaTags={data.allDatoCmsHomePage3.nodes[0].seoMetaTags}
            isHomepage
            colored
        >
            <div>
                <Banner
                    advertisingIsOver={dato.advertisingIsOver}
                    letsAmplify={dato.letsAmplify}
                    subtitle={dato.bannerSubtitle}
                    images={dato.bannerScenes}
                    buttonLabel={dato.startAmplifying}
                    overlayImage={dato.overlayImage}
                />

                <WhyAmplify data={dato.whyAmplify} />

                <HowItWorks
                    title={dato.howSectionTitle}
                    header={dato.howHeader}
                    steps={dato.howItWorks}
                />

                <Rules
                    rule={dato.rule}
                    rulesList={dato.rulesList}
                    buttonLabel={dato.startAmplifying}
                />

                <Members
                    benefitsList={dato.membersProsList}
                    bulletIcon={dato.membersProsListBullet}
                    header={dato.membersHeader}
                    polaroid={dato.polaroid}
                    subHeader={dato.membersSubHeader}
                    title={dato.membersSection}
                />

                <Benefits
                    cards={dato.benefitsCards}
                    discoverOurPlans={dato.discoverOurPlans}
                    header={dato.benefitsHeader}
                    locale={locale}
                    subHeader={dato.benefitsSubHeader}
                    title={dato.benefitsTitle}
                />

                <Campaigns buttonLabel={dato.startAmplifying} campaigns={dato.campaigns} />

                <ContactUs dato={dato} locale={locale} />
            </div>
        </DefaultTemplate>
    );
};

Home.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
};

export default styled(Home)`
    h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.mainColor};
        text-align: left;
        margin: 5px 0;

        @media screen and (max-width: ${({ theme }) => theme.responsiveWidth}) {
            text-align: center;
        }
    }
`;

export const query = graphql`
    query Home($locale: String) {
        allDatoCmsHomePage3(filter: { locale: { eq: $locale } }) {
            nodes {
                seoMetaTags {
                    ...GatsbyDatoCmsSeoMetaTags
                }
                startAmplifying
                ...BannerData
                ...WhyAmplifyData
                ...HowItWorksData
                ...RulesListData
                ...MembersProsData
                ...BenefitsData
                ...CampaignsData
                ...CalendlyData
                contactUsText
                contactUsButton
            }
        }
        allDatoCmsI18N(filter: { locale: { eq: $locale } }) {
            nodes {
                ...HeaderData
                ...FooterData
                ...CookieBannerData
                inspirationCellCta
                inspirationCreateCampaign
            }
        }
    }
`;
