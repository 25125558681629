import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Markdown from "../common/markdown";
import Button from "../common/button";
import theme from "../../utils/theme";

const StyledContactUs = styled.div`
    padding: 40px 0 150px 0;
    text-align: center;
    h2 {
        color: ${theme.altColor};
        margin: 30px auto;
        font-size: 35px;
        max-width: 500px;
    }
    button {
        background-color: white;
        border-radius: 20px/50%;
        padding: 15px 50px;
        font-weight: bold;
        font-size: 17px;
    }
`;

const ContactUs = ({ dato, locale }) => (
    <StyledContactUs>
        <Markdown value={dato.contactUsText} />
        <Button onClick={() => {}} data-action="contact_us" data-lang={locale} data-source="home">
            {dato.contactUsButton}
        </Button>
    </StyledContactUs>
);

ContactUs.propTypes = {
    dato: PropTypes.shape({
        contactUsText: PropTypes.string.isRequired,
        contactUsButton: PropTypes.string.isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
};

export default ContactUs;
