import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";

import MembersProsList from "./members-pros-list";
import SectionTitle from "./section-title";
import SectionHeader from "./section-header";
import TextDescription from "./text-description";
import CenterFrame from "../common/center-frame";

const Wrapper = styled.section`
    width: 100%;
    place-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        grid-template-columns: 1fr;
    }
`;

const Details = styled.div`
    height: auto;
    overflow: hidden;
    padding: 9px;

    @media only screen and (max-width: ${({ theme }) => theme.responsiveWidth}) {
        max-width: 500px;
        text-align: center;
        display: block;
        padding: 0;
        overflow: visible;
        margin-left: 15px;
        margin-right: 15px;
        p {
            width: 100%;
            padding: 0;
        }
    }
`;

const Description = styled(TextDescription)`
    font-size: 18px;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        font-size: 16px;
    }
`;

const Polaroid = styled.div`
    width: 500px;
    max-width: 80vw;
    img {
        width: 100%;
        height: auto;
    }

    @media only screen and (max-width: ${({ theme }) => theme.responsiveWidth}) {
        display: block;
        padding: 0;
        overflow: visible;
        img {
            width: 100%;
            height: auto;
        }
    }
`;

const Members = (props) => {
    const {
        benefitsList,
        bulletIcon,
        header,
        polaroid: { alt, url },
        subHeader,
        title,
    } = props;

    return (
        <CenterFrame>
            <Wrapper>
                <Details>
                    <SectionTitle>{title.toUpperCase()}</SectionTitle>
                    <SectionHeader>{header}</SectionHeader>
                    <Description>{subHeader}</Description>
                    <MembersProsList bulletIconUrl={bulletIcon.url} data={benefitsList} />
                </Details>
                <Polaroid>
                    <img src={url} alt={alt} />
                </Polaroid>
            </Wrapper>
        </CenterFrame>
    );
};

Members.propTypes = {
    benefitsList: PropTypes.string.isRequired,
    bulletIcon: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
    header: PropTypes.string.isRequired,
    polaroid: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
    }).isRequired,
    subHeader: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export const MembersProsData = graphql`
    fragment MembersProsData on DatoCmsHomePage3 {
        membersSection
        membersHeader
        membersSubHeader
        membersProsList
        membersProsListBullet {
            url
        }
        polaroid {
            url
            alt
        }
    }
`;

export default Members;
