import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SectionTitle from "./section-title";
import SectionHeader from "./section-header";

import ExternalLinkButton from "../common/external-link-button";
import { useAppLink } from "../../utils/hooks";

const RightSectionWrapper = styled.div`
    margin: auto;
    display: block;
    max-width: 500px;
    padding: 1.5em 1em;
    text-align: left;
    height: auto;
    color: white;
    > a {
        margin-top: 20px;
    }

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        margin-top: 0;
        text-align: center;
    }
`;

const CampaignImage = styled.img`
    width: 100%;
    max-width: 527px;
    max-height: 515.17px;
    height: auto;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        margin-top: 25px;
    }
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.01em;

    color: white;
    padding: 20px 0;

    strong {
        font-weight: 700;
    }

    p {
        font: inherit;
    }

    font-size: 15px;
    line-height: 26px;

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        font-size: 16px;
        line-height: 26px;
    }
`;

const Button = styled(ExternalLinkButton)`
    width: 194px;
    height: 38.4px;
`;

const CampaignItem = ({ data, buttonLabel }) => {
    const {
        campaignType,
        description,
        image: { url, alt },
        title,
    } = data;
    const editLink = useAppLink(process.env.GATSBY_EDIT_PATH, true, true);

    return (
        <>
            <CampaignImage src={url} alt={alt} />
            <RightSectionWrapper>
                <SectionTitle color="white">{campaignType.toUpperCase()}</SectionTitle>
                <SectionHeader>{title}</SectionHeader>
                <Description>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Description>
                <Button
                    eventName="click_campaign_button"
                    label={buttonLabel}
                    isWhiteBk
                    href={editLink}
                />
            </RightSectionWrapper>
        </>
    );
};

CampaignItem.propTypes = {
    data: PropTypes.shape({
        campaignType: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    buttonLabel: PropTypes.string.isRequired,
};

export default CampaignItem;
