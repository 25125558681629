import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql, Link } from "gatsby";

import { createLocaleLink } from "../../utils/locale";

import BenefitItem from "./benefit-item";
import SectionTitle from "./section-title";
import SectionHeader from "./section-header";
import TextDescription from "./text-description";
import CenterFrame from "../common/center-frame";

import backgroundBenefits from "../../images/css/background-benefits.svg";

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: inherit;

    background-image: url(${backgroundBenefits});
    background-size: cover;
    background-repeat: no-repeat;
`;

const Content = styled.div`
    padding-top: 5rem;
    padding-bottom: 6rem;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2rem;
    }
`;

const StyledButton = styled(Link)`
    background: ${({ theme }) => theme.mainColor};
    box-shadow: 2px 2px 20px rgba(15, 117, 191, 0.25);
    border-radius: 37px;
    width: 283px;
    height: 49.62px;
    border-color: transparent;
    padding: 2px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 17px;
    line-height: 18px;
    letter-spacing: 0.02em;

    color: #ffffff;

    @media (min-width: ${({ theme }) => theme.tabletFirstWidth}) {
        width: 212.35px;
        height: 40px;
    }

    @media (min-width: ${({ theme }) => theme.mobileViewWidth}) {
        width: 212.35px;
        height: 40px;
    }

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        width: 283px;
        height: 55px;
    }
`;

const Title = styled(SectionTitle)`
    padding-top: 40px;

    @media (min-width: ${({ theme }) => theme.responsiveWidth}) {
        padding-top: 0;
    }
`;

const CardsList = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(${({ count }) => count}, 1fr);
    margin: 20px auto;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        grid-template-columns: 1fr;
        max-width: 300px;
    }
`;

const ContentCentered = styled.div`
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        margin-left: 15px;
        margin-right: 15px;
    }
`;

const Description = styled(TextDescription)`
    font-size: 18px;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        font-size: 16px;
    }
`;

const Benefits = (props) => {
    const { cards, discoverOurPlans, header, locale, subHeader, title } = props;
    return (
        <Wrapper>
            <CenterFrame>
                <Content>
                    <ContentCentered>
                        <Title>{title.toUpperCase()}</Title>
                        <SectionHeader>{header}</SectionHeader>
                        <Description>{subHeader}</Description>
                    </ContentCentered>
                    <CardsList count={cards ? cards.length : 0}>
                        {cards && cards.map((card) => <BenefitItem key={card.order} data={card} />)}
                    </CardsList>
                    <ContentCentered>
                        <StyledButton to={createLocaleLink(locale, "pricing")}>
                            {discoverOurPlans}
                        </StyledButton>
                    </ContentCentered>
                </Content>
            </CenterFrame>
        </Wrapper>
    );
};

Benefits.propTypes = {
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            image: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired,
            }).isRequired,
            order: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    discoverOurPlans: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export const BenefitsData = graphql`
    fragment BenefitsData on DatoCmsHomePage3 {
        benefitsTitle
        benefitsHeader
        benefitsSubHeader
        benefitsCards {
            title
            description
            image {
                url
                alt
            }
            order
        }
        discoverOurPlans
    }
`;

export default Benefits;
