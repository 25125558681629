import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";

import RuleItem from "./rule-item";
import CenterFrame from "../common/center-frame";

const Box = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    height: 100%;
    background: linear-gradient(208.4deg, #ffffff 16.84%, rgba(255, 255, 255, 0) 139.66%), #d2efff;
    box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.15);

    padding: 0;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        margin-left: 15px;
        margin-right: 15px;
        flex-direction: column;
    }
`;

const Wrapper = styled.section`
    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        max-width: 500px;
        margin: 2em auto;
    }
`;

const Rules = ({ buttonLabel, rulesList, rule }) => (
    <CenterFrame>
        <Wrapper>
            <Box>
                {rulesList &&
                    rulesList.map((ruleItem) => (
                        <RuleItem
                            key={ruleItem.order}
                            buttonLabel={buttonLabel}
                            data={ruleItem}
                            rule={rule}
                        />
                    ))}
            </Box>
        </Wrapper>
    </CenterFrame>
);

Rules.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    rule: PropTypes.string.isRequired,
    rulesList: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired,
            }).isRequired,
            order: PropTypes.number.isRequired,
            summary: PropTypes.string.isRequired,
            keypoints: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export const rulesListData = graphql`
    fragment RulesListData on DatoCmsHomePage3 {
        rule
        rulesList {
            image {
                url
                alt
            }
            order
            summary
            keypoints
        }
    }
`;

export default Rules;
