import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SlidShowImage = styled(({ className, imageSrc }) => (
    <div className={className} style={{ backgroundImage: `url(${imageSrc})` }} />
))`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity ${({ transitionTime }) => transitionTime}s ease-in-out;
    opacity: ${({ active }) => (active ? 1.0 : 0.0)};
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 150%;
`;

const SlideShow = ({ className, images, transitionTime, displayTime }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const getNextIndex = (current) => {
        const nextIndex = current + 1;
        if (nextIndex >= images.length) {
            return 0;
        }
        return nextIndex;
    };

    const goNext = useCallback(() => {
        setCurrentIndex((index) => getNextIndex(index));
    }, [setCurrentIndex]);

    useEffect(() => {
        const timer = setInterval(goNext, (displayTime + transitionTime) * 1000);
        return () => {
            clearInterval(timer);
        };
    }, [images, displayTime, transitionTime]);

    return (
        <div className={className}>
            {images.map((src, idx) => (
                <SlidShowImage
                    key={src}
                    className="slideshow-image"
                    imageSrc={src}
                    active={idx === currentIndex}
                    transitionTime={transitionTime}
                />
            ))}
        </div>
    );
};

SlideShow.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    transitionTime: PropTypes.number,
    displayTime: PropTypes.number,
    className: PropTypes.string.isRequired,
};

SlideShow.defaultProps = {
    transitionTime: 1.0,
    displayTime: 5.0,
};

export default SlideShow;
