import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";

import SectionTitle from "./section-title";
import SectionHeader from "./section-header";
import CenterFrame from "../common/center-frame";
import imageHowToH from "../../images/css/how-it-works-h.svg";
import imageHowToV from "../../images/css/how-it-works-v.svg";

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 2em 0;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        text-align: center;
        align-items: center;
        padding: 0 2em;
    }
`;

const Steps = styled.div`
    max-width: 100%;
    background-image: url(${imageHowToH});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 6.2em;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(${({ $itemCount }) => $itemCount}, 1fr);
    grid-column-gap: 1em;
    align-items: flex-start;

    margin: 10px 0;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        max-width: 500px;
        background-image: url(${imageHowToV});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        grid-template-rows: repeat(${({ $itemCount }) => $itemCount}, 1fr);
        grid-template-columns: 1fr;
        height: 100%;
        width: auto;
        overflow: hidden;
    }
`;

const StepItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
`;

const StepItemImage = styled.img`
    grid-row: 1/2;
    height: 180px;
    background-color: transparent;

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        height: 160px;
        padding-bottom: 10px;
    }
`;

const StepItemTitle = styled.p`
    color: ${({ theme }) => theme.mainColor};

    font-weight: 600;
    text-align: center;
    letter-spacing: 0.01em;
    font-size: 16px;
    line-height: 18px;
    background-color: white;

    @media (min-width: ${({ theme }) => theme.mobileViewWidth}) {
        line-height: 21px;
        font-size: 18px;
    }
`;

const StepItemDescription = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.blackColor};
    text-align: center;
    background-color: white;
`;

const HowItWorks = ({ title, header, steps }) => (
    <CenterFrame>
        <Wrapper>
            <SectionTitle>{title.toUpperCase()}</SectionTitle>
            <SectionHeader>{header}</SectionHeader>
            <Steps $itemCount={steps ? steps.length : 0}>
                {/* <StepBkImage src={background.url} alt={background.alt} /> */}
                {steps &&
                    steps.map(({ image, description, title: stepTitle, order }) => (
                        <StepItem order={order} key={order}>
                            <StepItemImage src={image.url} alt={image.alt} />
                            <StepItemTitle className="sub2">{stepTitle}</StepItemTitle>
                            <StepItemDescription>{description}</StepItemDescription>
                        </StepItem>
                    ))}
            </Steps>
        </Wrapper>
    </CenterFrame>
);

HowItWorks.propTypes = {
    title: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired,
            }),
            description: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            order: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export const HowItWorksData = graphql`
    fragment HowItWorksData on DatoCmsHomePage3 {
        howSectionTitle
        howHeader
        howItWorks {
            image {
                url
                alt
            }
            description
            title
            order
        }
    }
`;

export default HowItWorks;
