import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import imageCheck from "../../images/css/check.svg";

const StyledDiv = styled.div`
    font-size: 16px;
    line-height: 37px;
    font-weight: 600;
    letter-spacing: 0.01em;

    color: ${({ theme }) => theme.mainColor};

    ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 1em 0;
    }

    span {
        color: ${({ theme }) => theme.blackColor};
    }

    ul li {
        position: relative;
        padding-left: 30px;
        &::before {
            margin-right: 5px;
            vertical-align: middle;
            font-size: 1.5em;
            line-height: 0;
            background-image: url(${imageCheck});
            content: "";
            width: 22px;
            height: 22px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: -1.2em;
        }
    }

    @media (max-width: ${({ theme }) => theme.responsiveWidth}) {
        font-size: 16px;
        display: flex;
        justify-content: center;
        ul {
            align-items: flex-start;
            margin: 0 auto;
        }
    }
`;

// eslint-disable-next-line react/no-danger
const MembersBenefitsList = ({ bulletIconUrl, data }) => (
    <StyledDiv bulletIconUrl={bulletIconUrl} dangerouslySetInnerHTML={{ __html: data }} />
);

MembersBenefitsList.propTypes = {
    bulletIconUrl: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
};

export default MembersBenefitsList;
